<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('details', element)"
                    v-bind:class="{even: element.getId() % 2 == 0, odd: element.getId() % 2 != 0, 'bg-warning':!element.getStock().isAvailable()}" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getUuid() }}
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            {{ element.getDisplayName() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            {{ element.getUnit() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Price :amount="element.getSellingPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            {{ element.getStock().quantity() }} {{element.getUnit()}}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <PackingTypes :packing-types="element.getPackingTypes()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Qty :amount="element.getMinBatchQuantity()" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="elements.isLoaded() ? $t('farmers.edit.empty_product') : $t('general.loading')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import PackingTypes from "@/components/elements/PackingTypes";

export default {
  name: "ProductTable",
  mixins: [ScreenSizeMixin, NumbersMixin],
  emits: ['confirm', 'details'],
  components: {PackingTypes, Qty, Price, TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
          'farmers.edit.id',
          'farmers.edit.product',
          'farmers.edit.unit',
          'farmers.edit.unit_price',
          'farmers.edit.stock',
          'farmers.edit.packing',
          'farmers.edit.min_order',
      ],
      responsiveHeaders: [
        'farmers.edit.product',
        'farmers.edit.unit',
        'farmers.edit.unit_price',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    }
  },
}
</script>

<style scoped>

</style>
