<template>
  <div>
    <a class="d-flex align-items-center" :class="{'flex-column flex-md-row': responsive}" v-bind:href="generateGoogleLink()" target="_blank">
      <i class="icon-home mr-md-3" :class="{'mr-3': !responsive, 'icon-2x': big}"></i>
      <div>
        <span>{{ address ? address.getFullAddress() : $t('general.loading') }}</span>
      </div>
    </a>
    <div v-if="hasPin" class="d-block text-right">
      <a v-bind:href="generateGoogleLink()" target="_blank">
        <span class="text-muted">*{{$t('frontoffice.drivers.info.maps')}}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Address",
  props: {
    address: Object,
    hasPin: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    generateGoogleLink: function () {
      if (this.address) {
        return this.$store.state.config.getGoogleMapsSearchTermUri(encodeURIComponent(this.address.getFullAddress()));
      }

      return '#';
    },
  }
}
</script>

<style scoped>
a {
  color: #333;
  text-decoration: none;
}
</style>
