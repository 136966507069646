<template>
  <Modal v-show="!isLoading" @closemodal="$emit('closemodal')" :with-footer="true" :dismissible="true">
    <template v-slot:default>
      <Card :title="title" :with-footer="false">
        <template v-slot:default>
          <div class="col-lg-10">
            <div class="form-group">
              <label>{{ $t('cycles.modal.send_to_farm_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.sendToFarmBatch.dayOfWeek" :options="daysOptions" :settings="sendToFarmBatchDaySelect2settings" v-bind:class="v$.cycle.sendToFarmBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.sendToFarmBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.send_to_farm_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.sendToFarmBatch.time" :options="timeOptions" :settings="sendToFarmBatchTimeSelect2settings" v-bind:class="v$.cycle.sendToFarmBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.sendToFarmBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.prepack_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.prepackBatch.dayOfWeek" :options="daysOptions" :settings="prepackBatchDaySelect2settings" v-bind:class="v$.cycle.prepackBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.prepackBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.prepack_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.prepackBatch.time" :options="timeOptions" :settings="prepackBatchTimeSelect2settings" v-bind:class="v$.cycle.prepackBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.prepackBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.pack_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.packBatch.dayOfWeek" :options="daysOptions" :settings="packBatchDaySelect2settings" v-bind:class="v$.cycle.packBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.packBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.pack_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.packBatch.time" :options="timeOptions" :settings="packBatchTimeSelect2settings" v-bind:class="v$.cycle.packBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.packBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('farmers.cycles.modal.send_to_collection') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.sendToCollectionBatch.dayOfWeek" :options="daysOptions" :settings="sendToCollectionDaySelect2settings" v-bind:class="v$.cycle.sendToCollectionBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.sendToCollectionBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.send_to_collection_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.sendToCollectionBatch.time" :options="timeOptions" :settings="sendToCollectionTimeSelect2settings" v-bind:class="v$.cycle.sendToCollectionBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.sendToCollectionBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
      <NotificationAlert :notifications="notification" />
    </template>
    <template v-slot:footer>
      <Button v-bind:disabled="canDisableButtons" @click="createCycle()" class="mr-auto" v-if="!cycle.isLoaded()" :name="$t('cycles.buttons.create_small')" type="confirm"></Button>
      <Button v-bind:disabled="canDisableButtons" @click="updateCycle" class="mr-auto" v-else :name="$t('cycles.buttons.update')" type="confirm"></Button>
    </template>
  </Modal>
</template>

<script>
import Card from "@/components/elements/Card";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizationMixin, ValidationsMixin, NotificationsMixin, DatesMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import NotificationAlert from "@/components/elements/NotificationAlert";
import Select2 from 'vue3-select2-component';
import Cycle from "@/entities/Cycle";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "FarmerCycleModal",
  components: {NotificationAlert, Button, Modal, Card, Select2},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin, DatesMixin],
  emits: ['closemodal'],
  watch: {
    cycle: {
      immediate: true,
      deep: true,
      handler: function (before) {
        if (before === null) {
          return;
        }
        this.cycle.setChanged(true);
      }
    },
  },
  validations: {
    cycle: {
      sendToFarmBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      prepackBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      packBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      sendToCollectionBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
    },
  },
  props: {
    farmer: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    let that = this;
    return {
      cycles: this.farmer.getCycles(),
      cycle: new Cycle(),
      sendToFarmBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_farm_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        multiple: true,
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToFarmBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_farm_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      prepackBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.prepack_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        multiple: true,
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      prepackBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.prepack_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      packBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.pack_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        multiple: true,
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      packBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.pack_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToCollectionDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_collection'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        multiple: true,
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToCollectionTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_collection_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
    }
  },
  beforeMount() {
    this.generateCycleFromList();
  },
  computed: {
    title: function () {
      if (this.cycle === null || !this.cycle.isLoaded()) {
        return this.$t("cycles.modal.create_title");
      }

      return this.$t("cycles.modal.edit_title");
    },
    isFormReady: function () {
      return this.cycle.isChanged() && !(
          this.v$.cycle.sendToFarmBatch.dayOfWeek.$error ||
          this.v$.cycle.sendToFarmBatch.time.$error ||
          this.v$.cycle.prepackBatch.dayOfWeek.$error ||
          this.v$.cycle.prepackBatch.time.$error ||
          this.v$.cycle.packBatch.dayOfWeek.$error ||
          this.v$.cycle.packBatch.time.$error ||
          this.v$.cycle.sendToCollectionBatch.dayOfWeek.$error ||
          this.v$.cycle.sendToCollectionBatch.time.$error
      );
    },
    canDisableButtons: function () {
      return !this.cycle.isChanged() || !this.isFormReady;
    }
  },
  methods: {
    generateCycleFromList: function () {
      let that = this;
      this.cycle.sendToFarmBatch.dayOfWeek = [];
      this.cycle.prepackBatch.dayOfWeek = [];
      this.cycle.packBatch.dayOfWeek = [];
      this.cycle.sendToCollectionBatch.dayOfWeek = [];

      this.farmer.getCycles().getItems().forEach(cycle => {
        that.cycle.sendToFarmBatch.dayOfWeek.push(cycle.getSendToFarmBatch().getDayOfWeek());
        that.cycle.prepackBatch.dayOfWeek.push(cycle.getPrepackBatch().getDayOfWeek());
        that.cycle.packBatch.dayOfWeek.push(cycle.getPackBatch().getDayOfWeek());
        that.cycle.sendToCollectionBatch.dayOfWeek.push(cycle.getSendToCollectionBatch().getDayOfWeek());

        if (cycle.getSendToFarmBatch().getTime()) {
          that.cycle.sendToFarmBatch.time = cycle.getSendToFarmBatch().getTime();
        }
        if (cycle.getPrepackBatch().getTime()) {
          that.cycle.prepackBatch.time = cycle.getPrepackBatch().getTime();
        }
        if (cycle.getPackBatch().getTime()) {
          that.cycle.packBatch.time = cycle.getPackBatch().getTime();
        }
        if (cycle.getSendToCollectionBatch().getTime()) {
          that.cycle.sendToCollectionBatch.time = cycle.getSendToCollectionBatch().getTime();
        }
        that.cycle.setLoaded(true);
      });
    },
    generateListFromCycle: function () {
      let cycles = [];
      let max = Math.max(...[
        this.cycle.sendToFarmBatch.dayOfWeek.length,
        this.cycle.prepackBatch.dayOfWeek.length,
        this.cycle.packBatch.dayOfWeek.length,
        this.cycle.sendToCollectionBatch.dayOfWeek.length
      ]);

      for (let i = 0; i < max; i++) {
        let data = {};
        if (this.cycle.sendToFarmBatch.dayOfWeek[i] !== null) {
          data.send_to_farm_batch = {
            day_of_week: this.cycle.sendToFarmBatch.dayOfWeek[i],
            time: this.cycle.sendToFarmBatch.time,
          }
        }
        if (this.cycle.prepackBatch.dayOfWeek[i] !== null) {
          data.prepack_batch = {
            day_of_week: this.cycle.prepackBatch.dayOfWeek[i],
            time: this.cycle.prepackBatch.time,
          };
        }
        if (this.cycle.packBatch.dayOfWeek[i] !== null) {
          data.pack_batch = {
            day_of_week: this.cycle.packBatch.dayOfWeek[i],
            time: this.cycle.packBatch.time,
          };
        }
        if (this.cycle.sendToCollectionBatch.dayOfWeek[i] !== null) {
          data.send_to_collection_batch = {
            day_of_week: this.cycle.sendToCollectionBatch.dayOfWeek[i],
            time: this.cycle.sendToCollectionBatch.time,
          };
        }

        cycles.push(new Cycle(data));
      }

      return cycles;
    },
    setName: function (cycle, value)
    {
      cycle.setName(value);
      this.v$.cycle.name.$touch();
    },
    onLoadingComplete: function ()
    {
      setTimeout(function () {
        document.getElementsByTagName("body")[0].classList.add('modal-open');
      }, 2000);

      if (this.initialise && !this.canShowNotification) {
        this.$emit('closemodal');
      } else {
        this.addSuccessNotification("general.success");
      }
    },
    validateInputs: function ()
    {
      this.v$.cycle.sendToFarmBatch.dayOfWeek.$touch();
      this.v$.cycle.sendToFarmBatch.time.$touch();
      this.v$.cycle.prepackBatch.dayOfWeek.$touch();
      this.v$.cycle.prepackBatch.time.$touch();
      this.v$.cycle.packBatch.dayOfWeek.$touch();
      this.v$.cycle.packBatch.time.$touch();
      this.v$.cycle.sendToCollectionBatch.dayOfWeek.$touch();
      this.v$.cycle.sendToCollectionBatch.time.$touch();
    },
    updateCycle: function ()
    {
      let that = this;
      this.validateInputs();
      if (!this.isFormReady) {
        return;
      }

      this.queueJob();
      this.deleteCycles().then(() => {
        that.createCycle(false);
      }).finally(() => {
        that.completeJob();
      });
    },
    createCycle: function (canInitialise = true)
    {
      let that = this;

      this.validateInputs();
      if (!this.isFormReady) {
        return;
      }
      this.resetNotifications();

      this.generateListFromCycle().forEach(cycle => {
        let data = {
          'name': that.farmer.getName() + "'s cycle",
        };
        if (cycle.getSendToFarmBatch().getDayOfWeek()) {
          data.send_to_farm_batch = {
            day: cycle.getSendToFarmBatch().getDayOfWeek(),
            time: cycle.getSendToFarmBatch().getTime()
          };
        }
        if (cycle.getPrepackBatch().getDayOfWeek()) {
          data.prepack_batch = {
            day: cycle.getPrepackBatch().getDayOfWeek(),
            time: cycle.getPrepackBatch().getTime()
          };
        }
        if (cycle.getPackBatch().getDayOfWeek()) {
          data.pack_batch = {
            day: cycle.getPackBatch().getDayOfWeek(),
            time: cycle.getPackBatch().getTime()
          };
        }
        if (cycle.getSendToCollectionBatch().getDayOfWeek()) {
          data.send_to_collection_batch = {
            day: cycle.getSendToCollectionBatch().getDayOfWeek(),
            time: cycle.getSendToCollectionBatch().getTime()
          };
        }

        that.queueJob();
        that.axios.post(this.$store.state.config.getFarmerCreateCycleUri(that.farmer.getId()), data).catch(
            error => {
              if (error.response && error.response.data.messages) {
                error.response.data.messages.forEach(message => that.addWarningNotification(message));
              }
            }
        ).finally(function () {
          that.completeJob(canInitialise);
        });
      });
    },
    deleteCycles: function () {
      let that = this;
      return this.axios.delete(this.$store.state.config.getFarmerDeleteCyclesUri(this.farmer.getId())).then(
          response => {
            let content = response.data;
            return Promise.resolve(content);
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
            return Promise.reject(error);
          }
      );
    }
  },
}
</script>

<style scoped>

</style>