<template>
  <div v-if="farmer.length > 0">
    <span class="text-center d-block" v-for="packing in farmer" :key="packing.getName()">
      {{ packing.getName() }}
    </span>
  </div>
  <span v-else class="text-center">-</span>
</template>

<script>
import Collection from "@/entities/Collection";

export default {
  name: "PackingTypes",
  props: {
    packingTypes: {
      type: Object,
      default: function () { return new Collection(); }
    },
  },
  computed: {
    farmer: function () {
      return this.packingTypes.getItems().filter(packing => packing.isFarmer());
    },
    customer: function () {
      return this.packingTypes.getItems().filter(packing => packing.isCustomer());
    },
  }
}
</script>

<style scoped>

</style>