<template>
  <Table :hoverable="false">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="element">
      <TableElement @click="$emit('details', element)"
                    class="even">
        <TableElementItem :centered="true">
          <template v-slot:default>
            <span class="text-nowrap">{{$t('farmers.total_products', {nr: formatNumber(element.getTotalProducts())})}}</span>
            <span class="d-block text-nowrap">{{$t('farmers.available_products', {nr: formatNumber(element.getAvailableProducts())})}}</span>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <span class="d-block">{{element.getProductCategoriesLabels()}}</span>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <span class="d-block text-nowrap">{{$t('farmers.en_gross_products_active', {nr: formatNumber(element.getEnGrossProducts())})}}</span>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <DayOfWeek :days="element.getSendToCollectionWeekDays()" :time="element.getSendToCollectionTime()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Date :date="element.getUpdatedAt()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status :status="element.isActive() ? 'farmers.active' : 'farmers.inactive'" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="!element" >
      <TableElement :size="headers.length" :empty-message="$t('general.loading')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin, DatesMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Date from "@/components/elements/Date";
import Status from "@/components/elements/Status";
import DayOfWeek from "@/components/elements/DayOfWeek";

export default {
  name: "FarmerEditTable",
  mixins: [ScreenSizeMixin, NumbersMixin, DatesMixin],
  emits: ['confirm', 'details'],
  components: {DayOfWeek, Status, Date, TableElement, TableElementItem, Table},
  props: {
    element: Object
  },
  data() {
    return {
      allHeaders: [
        'farmers.edit.products_count',
        'farmers.edit.product_categories',
        'farmers.edit.en_gross_products_count',
        'farmers.edit.delivery_days',
        'farmers.edit.last_update',
        'farmers.edit.status',
      ],
      responsiveHeaders: [
        'farmers.edit.products_count',
        'farmers.edit.en_gross_products_count',
        'farmers.edit.delivery_days',
        'farmers.edit.status',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
  },
}
</script>

<style scoped>

</style>
