<template>
  <FarmerCycleModal @closemodal="closeCycleModal" v-if="showCycleModal" :farmer="farmer"></FarmerCycleModal>
  <ProductDetailsModal @closemodal="selectedProduct=null" v-if="selectedProduct" :product="selectedProduct" />
  <Card :with-footer="true" :title="farmer ? farmer.getName() : 'general.loading'" >
      <template v-slot:titleimage>
          <div v-if="farmer && farmer.getLogo() && farmer.getLogo().url" class="mr-2">
              <img :src="farmer.getLogo().url" alt="" style="height: 50px; " />
          </div>
      </template>
      <template v-slot:subtitle>
          <div v-if="farmer && farmer.sellsImportProducts()">
              <span class="badge badge-info" >{{$t('farmers.import')}}</span>
          </div>
      </template>
    <template v-slot:default>
      <div class="d-xl-flex justify-content-lg-between">
        <div class="col-12 col-xl-8">
          <FarmerEditTable :element="farmer" />
        </div>
        <div class="d-block align-self-xl-start ml-xl-4">
          <Call class="d-block mb-2" :show="true" :telephone="farmer && farmer.getTelephone()" />
          <Email class="d-block mb-2" :show="true" :email="farmer && farmer.getEmail()" />
          <Address class="d-block mb-2" :address="farmer && farmer.getAddress()" />
          <div class="d-block mb-2" v-if="farmer">
            <img :src="'https://www.worldatlas.com/r/w425/img/flag/' + farmer.getCountry().toLowerCase() +'-flag.jpg'" style="height: 15px;" class="inline-block mr-2" v-if="farmer.getCountry()"  /> {{farmer.getCounty()}}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button @click="showCycleModal=true" type="eye" :name="$t('farmers.buttons.cycle')" />
        <Button @click="$emit('update', farmer)" class="mr-auto w-100 w-lg-auto ml-2" :name="$t('farmers.buttons.update')" type="confirm"></Button>
    </template>
  </Card>
  <Card>
    <template v-slot:default>
      <ProductTable @details="showProductDetails" @page="getProducts" :elements="products" />
    </template>
    <template v-slot:footer>
      <div class="mr-auto">
        <Button @click="downloadFarmerProducts" type="download" class="mr-auto mb-2 w-100 w-lg-auto" :name="$t('farmers.buttons.download')" />
      </div>
    </template>
  </Card>
  <EnGrossProductCard :farmer-id="$route.params.id" />
</template>

<script>
import Card from "@/components/elements/Card";
import Farmer from "@/entities/Farmer";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import FarmerEditTable from "@/components/farmer/backoffice/FarmerEditTable";
import Call from "@/components/elements/Call";
import Email from "@/components/elements/Email";
import Address from "@/components/elements/Address";
import ProductTable from "@/components/farmer/backoffice/ProductTable";
import LoadableCollection from "@/entities/LoadableCollection";
import Pagination from "@/entities/Pagination";
import Product from "@/entities/Product";
import ProductDetailsModal from "@/components/farmer/backoffice/ProductDetailsModal";
import {DatesMixin, DownloadMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Button from "@/components/elements/Button";
import FarmerCycleModal from "@/components/farmer/backoffice/FarmerCycleModal";
import EnGrossProductCard from "@/components/engross/backoffice/EnGrossProductCard";

export default {
  name: "FarmerEdit",
  components: {
    EnGrossProductCard,
    FarmerCycleModal, Button, ProductDetailsModal, ProductTable, Address, Email, Call, FarmerEditTable, Card},
  mixins: [LoadingModalMixin, ScreenSizeMixin, DatesMixin, DownloadMixin],
  emits: ['update', 'refresh'],
  data() {
    return {
      farmer: null,
      products: new LoadableCollection(),
      enGrossProducts: new LoadableCollection(),
      selectedProduct: null,
      showCycleModal: false,
      allFarmerHeaders: [
        'farmers.list.products_count',
        'farmers.list.delivery_days',
        'farmers.list.last_update',
        'farmers.list.status',
      ]
    }
  },
  mounted() {
    this.getFarmer();
    this.getProducts();
  },
  unmounted() {
    this.$store.commit("breadcrumb", "");
  },
  methods: {
    showProductDetails: function (product) {
      if (this.isScreenLgWidth) {
        return;
      }

      this.selectedProduct = product;
    },
    getProducts: function (pageNr = 1) {
      let that = this;
      let localProducts = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getProductsUri(this.$route.params.id), {params: filterParams}).then(
          response => {
            let content = response.data;
            localProducts.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (product) {
              localProducts.push(new Product(product));
            });

            localProducts.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localProducts.isLoaded()) {
          that.products.empty();
          that.products.setPagination(localProducts.getPagination());
          localProducts.getItems().forEach(product => that.products.push(product));
          that.products.loaded();
        }
        that.completeJob();
      });
    },
    closeCycleModal: function () {
      this.showCycleModal = false;
      this.getFarmer();
      this.$emit('refresh');
    },
    getFarmer: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getFarmerUri(this.$route.params.id)).then(
          response => {
            let content = response.data;
            that.farmer = new Farmer(content.data);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit('breadcrumb', that.farmer && that.farmer.getName());
        that.completeJob();
      });
    },
    downloadFarmerProducts: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getProductsDownloadUri(this.$route.params.id), { responseType: 'blob' }).then(
          response => {
            let blob = new Blob([response.data], { type: "text/csv" });
            let fileName = that.farmer.getName() + "_products_" + this.formatDateTime(new Date()) + ".csv";
            that.downloadFileFromBlob(fileName, blob);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    }
  }
}
</script>

<style scoped>

</style>
