<template>
  <Modal :dismissible="true" :with-footer="false">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('farmers.edit.details.uuid')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{product.getUuid()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('farmers.edit.details.product')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{product.getDisplayName()}}
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.edit.details.unit')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          {{ product.getUnit() }}
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.edit.details.unit_price')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="product.getSellingPriceWithVat()" :currency="product.getCurrency()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.edit.details.min_order')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Qty class="qty text-left pl-0 pr-0" :amount="product.getMinBatchQuantity()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('farmers.edit.details.packing')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <PackingTypes :packing-types="product.getPackingTypes()" />
        </dd>
      </dl>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import PackingTypes from "@/components/elements/PackingTypes";

export default {
  name: "ProductDetailsModal",
  components: {PackingTypes, Qty, Price, Modal},
  mixins: [NumbersMixin],
  emits: ['confirm'],
  props: {
    product: Object,
  },
}
</script>

<style scoped>
.qty {
  text-align: left !important;
}
</style>